.sizeFull {
  height: 100vh;
}
.sizeLg {
  height: 70vh;
}
.sizeMd {
  height: 50vh;
}
.sizeSm {
  height: 35vh;
}
.sizeXs {
  height: 28vh;
}
.h50 {
  height: 50%;
}
