.heroRoot {
}
.heroRoot :global(.swiper-button-prev:after) {
  font-size: 18px;
  color: #7e7e7e;
}
.heroRoot :global(.swiper-button-next:after) {
  font-size: 18px;
  color: #7e7e7e;
}
.slideRoot {
}
.slideRoot :global(.hero-contents) > * {
  opacity: 0;
  transform: translateY(20px);
}
.slideContent {
  position: static;
  width: 100%;
  text-align: left;
  padding-bottom: 10%;
  padding-left: 6%;
}
